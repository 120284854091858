export default function Field ({
  error = '',
  id,
  label,
  onChange = () => undefined,
  type = 'text',
  value
}) {
  return (
    <fieldset
      className="mt-2"
    >
      {type === 'hidden' ? '' : (<label>{label}</label>)}
      <input
        type={type}
        className="form-control mt-1"
        id={id}
        value={value}
        onChange={onChange}
      />
      {error
        ? (
        <div style={{ color: 'red', textAlign: 'center' }}>
          {error}
        </div>
          )
        : (
            ''
          )}
    </fieldset>
  )
}
