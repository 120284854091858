import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './ResultPage.css'

function ProductNullPage () {
  const location = useLocation()
  const navigate = useNavigate()
  const productDataValue = location.state?.productDataValue
  console.log('productDataValue', productDataValue.productData)

  const goBack = () => {
    navigate('/')
  }
  return (
    <div>
      {productDataValue?.productData === undefined ||
        (productDataValue?.productData === null && (
          <div>
            <div className="back_button_design">
              <button type="button" className="btn btn-dark" onClick={goBack}>
                Back
              </button>
            </div>
            <div className="message_container">
              <b>
                Oops we are unable to process your request right now,
                <br /> as there are no products available on your store.
                <br /> Please try again later.
              </b>
            </div>
          </div>
        ))}
    </div>
  )
}

export default ProductNullPage
