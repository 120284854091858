import axios from 'axios'
import { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { BeatLoader } from 'react-spinners'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './QuotePage.css'
import AssurefulWhiteLogo from '../assets/images/AssurefulWhiteBlueLogo.png'
import useLocalStorageState from '../hooks/useLocalStorageState'
import Field from './Forms/Field'
import ResultPage from './ResultPage'

function QuotePage () {
  const [searchParams] = useSearchParams()
  const [sellerId, setSellerId] = useLocalStorageState('sellerId', '')
  const [revenueData, setRevenueData] = useLocalStorageState('revenueData', '')
  const [brokerEmail] = useState(searchParams.get('broker-email'))
  const [brokerName] = useState(searchParams.get('broker-name'))
  const [companyName, setCompanyName] = useLocalStorageState('companyName', '')
  const [firstName, setFirstName] = useLocalStorageState('firstName', '')
  const [lastName, setLastName] = useLocalStorageState('lastName', '')
  const [email, setEmail] = useLocalStorageState('email', '')
  const [phoneNumber, setPhoneNumber] = useLocalStorageState('phoneNumber', '')
  const [totalPrem, setTotalPrem] = useState()
  const [sellerIdError, setSellerIdError] = useState('')
  const [monthlyRevenueError, setMonthlyRevenueError] = useState('')
  const [companyNameError, setCompanyNameError] = useState('')
  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [phoneNumberError, setPhoneNumberError] = useState()
  const [flag, setFlag] = useState(true)
  const [openModalCustomer, setOpenModalCustomer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openModalDetail, setOpenModalDetail] = useState(false)
  const [loadingRevenue, setLoadingRevenue] = useState(false)
  const navigate = useNavigate()

  const handleChangeSellerId = (e) => {
    setSellerIdError('')
    const sellerIdPattern = /[^a-zA-Z0-9\s]*$/
    const revData = e.target.value
    if (sellerIdPattern.test(revData)) {
      setSellerId(revData)
    }
  }

  const validateSellerId = (sellerId) => {
    if (sellerId === '') {
      setSellerIdError('Please Enter SellerId')
    } else if (sellerId.trim() === '' || !/^[a-zA-Z0-9\s]+$/.test(sellerId)) {
      setSellerIdError('Invalid SellerId')
    } else {
      setSellerIdError('')
      return true
    }
  }

  const handleChangeRevenue = (e) => {
    setMonthlyRevenueError('')
    const value = e.target.value.replace('$', '') // Remove dollar symbol if present
    if (/^\d*(\.\d{0,2})?$/.test(value)) {
      setRevenueData(value)
    }
  }

  const validateRevenue = (revenueData) => {
    if (revenueData === '') {
      setMonthlyRevenueError('Enter Monthly Revenue')
    } else if (!/^\d+(\.\d{0,2})?$/.test(revenueData)) {
      setMonthlyRevenueError('Invalid Revenue')
    } else {
      setMonthlyRevenueError('')
      return true
    }
  }

  const handleChangeCompanyName = (e) => {
    setCompanyNameError('')
    setCompanyName(e.target.value)
  }

  const handleChangeFirstName = (e) => {
    setFirstNameError('')
    setFirstName(e.target.value)
  }

  const validateAlphaNum = (str, setError, label) => {
    if (str === '' || str.trim() === '') {
      setError(`Please Enter ${label}`)
    } else if (
      /^\d+$/.test(str.trim()) ||
      !/^[a-zA-Z][a-zA-Z0-9\s]*$/.test(str.trim())
    ) {
      setError(
        `${label} must start with a letter. Special characters are not allowed.`
      )
    } else {
      setError('')
      return true
    }
  }

  const handleChangeLastName = (e) => {
    setLastNameError('')
    setLastName(e.target.value)
  }

  const handleChangeEmail = (e) => {
    setEmailError('')
    setEmail(e.target.value)
  }

  const validateEmail = (email) => {
    setEmailError('')
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (emailPattern.test(email.trim())) {
      return true
    } else if (email === '') {
      setEmailError('Please Enter Email')
    } else {
      setEmailError('Invalid Email')
    }
  }

  const handleChangeNumber = (e) => {
    setPhoneNumberError('')
    const phoneNumber = e.target.value
    if (/^[0-9\s]*$/.test(phoneNumber)) {
      setPhoneNumber(phoneNumber)
    }
  }

  const validateNumber = (phoneNumber) => {
    if (phoneNumber === '') {
      setPhoneNumberError('Please Enter Phone Number')
    } else if (phoneNumber.trim().length !== 10) {
      setPhoneNumberError('Invalid Phone Number')
    } else {
      setPhoneNumberError('')
      return true
    }
  }

  const getRevenue = async () => {
    if (validateSellerId(sellerId)) {
      setLoadingRevenue(true)
      axios
        .get(
          `https://aiinslyapiuat.assureful.com/monthlyRevenue?sellerId=${sellerId.trim()}`
        )
        .then((response) => {
          const rev = response.data.monthlyRevenue?.toFixed(2)
          setRevenueData(rev)
          if (response.data.status === 'ERROR') {
            setRevenueData('')
            toast.error(response.data.messages[0].description)
            // setSellerIdError("REVENUE_NOT_AVAILABLE");
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error?.response?.status === 500) { setSellerIdError('Please Enter Valid Seller Id') }
          }
        })
        .finally(() => {
          setLoadingRevenue(false)
        })
    } else {
      setSellerIdError('Special Characters are not allowed')
    }
  }

  const formValidation = () =>
    validateSellerId(sellerId) &&
    validateRevenue(revenueData) &&
    validateAlphaNum(companyName, setCompanyNameError, 'Company Name') &&
    validateAlphaNum(firstName, setFirstNameError, 'First Name') &&
    validateAlphaNum(lastName, setLastNameError, 'Last Name') &&
    validateEmail(email) &&
    validateNumber(phoneNumber)

  const postData = {
    brokerEmail: brokerEmail,
    brokerName: brokerName,
    customerInfo: {
      brandName: sellerId.trim(),
      revenue: revenueData.trim(),
      companyName: companyName.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      phone: phoneNumber.trim()
    }
  }

  const submitData = (e) => {
    if (formValidation()) {
      setLoading(true)
      setOpenModalDetail(true)
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URI}/customerInformation`,
          postData
        )
        .then((response) => {
          if (response.data.status === 'SUCCESS') {
            // if(/^\d*(\.\d{0,2})?$/.test(response.data.customerInfo.totalPrem))
            setTotalPrem(response.data.customerInfo.totalPrem)
            setOpenModalCustomer(true)
            setFlag(response.data.customerInfo.flag)
            setOpenModalDetail(false)
            setLoading(false)
            RedirectToResults(response.data)
          } else {
            if (response.data.status === 'ERROR') {
              RedirectToResultsPage(response.data)
              // console.log(response.data.productData);
              toast.warn('Oops we are unable to process your request')
              // navigate("/");
              setLoading(false)
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            RedirectToResultsPage(error)
            // toast.warn("Oops we are unable to process your request");
            // navigate("/");
            setLoading(false)
          }
        })
    }
  }

  const RedirectToResults = (data) => {
    console.log(data)
    const resultValues = {
      sellerid: data?.customerInfo?.brandName,
      monthlyRevenue: data?.customerInfo?.revenue,
      staticPremium: data?.customerInfo?.staticPrem,
      totalVariablePremium: data?.customerInfo?.totalVariablePrem,
      totalPremium: data?.customerInfo?.totalPrem,
      contactSalesFlag: data?.customerInfo?.contactSalesFlag,
      productData: data?.productData
    }
    navigate('/result', {
      state: {
        resultValues
      }
    })
  }

  const RedirectToResultsPage = (data) => {
    const productDataValue = {
      productData: data?.productData
    }
    navigate('/productResult', {
      state: { productDataValue }
    })
  }

  const closeOpenDetail = () => {
    setOpenModalDetail(false)
  }

  const clearData = () => {
    setSellerId('')
    setRevenueData('')
    setCompanyName('')
    setFirstName('')
    setLastName('')
    setEmail('')
    setPhoneNumber('')
  }

  const formattedTotalPrem = Number(totalPrem)
    .toFixed(2)
    .replace(/\.?0+$/, '')

  return (
    <>
      <div className="asf_onboarding_container_header">
        <div className=" asf_onboarding_container-right-logo">
          <img src={AssurefulWhiteLogo} className="asf_brand_logo" alt="logo" />
        </div>
      </div>
      <ToastContainer autoClose={500} />
      <div className="quote_container">
        <div className="result_container">{!flag ? <ResultPage /> : ''}</div>

        <div className="result_container">
          {openModalCustomer && flag
            ? (
            <ResultPage apiResponse={formattedTotalPrem} />
              )
            : (
                ''
              )}
        </div>

        {loading && formValidation && (
          <div className="loading-overlay">
            <BeatLoader
              color="black"
              loading={loading}
              size="12px"
              margin={50}
            />
          </div>
        )}

        <Modal isOpen={openModalDetail}>
          <ModalHeader>Info</ModalHeader>
          <ModalBody style={{ textAlign: 'center' }}>
            {' '}
            Your data is processing. Please wait...
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ color: 'white', backgroundColor: 'black' }}
              onClick={closeOpenDetail}
            >
              {' '}
              Ok
            </Button>{' '}
          </ModalFooter>
        </Modal>

        <div className="container">
          <h3>
            Get an Instant Indicative Price for your E-Commerce Business… <br />
            It takes less than 60 seconds.{' '}
          </h3>
          {/* <form> */}
          <div className="hidden">
            <Field
              id="brokerEmail"
              label="Broker Email"
              type="hidden"
              value={brokerEmail}
            />
            <Field
              id="brokerName"
              label="Broker Name"
              type="hidden"
              value={brokerName}
            />
          </div>
          <div>
            <div className="form-group mt-2">
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 'auto' }}>
                  <Field
                    error={sellerIdError}
                    id="sellerId"
                    label="Enter your brand name of Seller ID:"
                    onChange={handleChangeSellerId}
                    value={sellerId}
                  />
                </div>
                <div className="button" style={{ flex: 'auto' }}>
                  <button
                    type="button"
                    className="btn mt-2 w-100"
                    onClick={getRevenue}
                    disabled={!sellerId.trim()}
                  >
                    {loadingRevenue
                      ? <BeatLoader color={'#fff'} style={{ margin: '20px' }} />
                      : 'Get Revenue'
                    }
                  </button>
                </div>
              </div>
              <Field
                error={monthlyRevenueError}
                id="monthlyRevenue"
                label="We price our insurance each month based on how much you sold in the past month. Enter your average monthly sales here if our estimate is too high:"
                onChange={handleChangeRevenue}
                value={`$${revenueData}`}
              />

              <div className="mt-4">
                Great, to get your estimate please enter your details below :{' '}
              </div>

              <Field
                error={companyNameError}
                id="companyName"
                label="Company Name"
                onChange={handleChangeCompanyName}
                value={companyName}
              />

              <Field
                error={firstNameError}
                id="firstName"
                label="First Name"
                onChange={handleChangeFirstName}
                value={firstName}
              />

              <Field
                error={lastNameError}
                id="lastName"
                label="Last Name"
                onChange={handleChangeLastName}
                value={lastName}
              />

              <Field
                error={emailError}
                id="email"
                label="Email"
                onChange={handleChangeEmail}
                value={email}
              />

              <Field
                error={phoneNumberError}
                id="phoneNumber"
                label="Phone Number"
                onChange={handleChangeNumber}
                value={phoneNumber}
              />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 'auto', marginRight: '1rem' }}>
              <button
                type="submit"
                className="btn mt-4 w-100"
                onClick={submitData}
              >
                Submit
              </button>
            </div>

            <div style={{ flex: 'auto', marginLeft: '1rem' }}>
              <button
                type="submit"
                className="btn mt-4 w-100"
                onClick={clearData}
              >
                Clear
              </button>
            </div>
          </div>

          {/* </form> */}
        </div>
      </div>
    </>
  )
}

export default QuotePage
