import './App.css'
import MainRoutes from './Routes'

function App () {
  return (
    <MainRoutes/>
  )
}

export default App
