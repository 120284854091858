import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'

import './ResultPage.css'
import AssurefulWhiteLogo from '../assets/images/AssurefulWhiteBlueLogo.png'

function ResultPage () {
  const location = useLocation()
  const navigate = useNavigate()
  const resultValues = location.state?.resultValues
  const totalVariablePremium = Number(
    resultValues?.totalVariablePremium
  ).toFixed(2)
  const contactSalesFlag = resultValues?.contactSalesFlag
  const productData = resultValues?.productData
  const goBack = () => {
    navigate('/')
  }

  return (
    <>
      <div className="asf_onboarding_container_header">
        <div className=" asf_onboarding_container-right-logo">
          <img src={AssurefulWhiteLogo} className="asf_brand_logo" alt="logo" />
        </div>
      </div>
      <div className="container">
        {/* {resultValues?.monthlyRevenue >= 15000000 && !flag ? ( */}

        {contactSalesFlag
          ? (
          <div>
            <div className="back_button_design">
              <button type="button" className="btn btn-dark" onClick={goBack}>
                Back
              </button>
            </div>
            <div className="message_container">
              <b>
                Your Annual revenue is more than 15M.
                <br />
                To get a complete and accurate quote,
                <br />
              </b>
              <b>
                Please get in touch with our sales team at{' '}
                <a style={{ color: 'blue' }} href="">
                  sales@assureful.com
                </a>
              </b>
            </div>
          </div>
            )
          : (
          <div>
            <div className="customerinfo">
              <div>
                <div>Seller Id</div>
                <div>Monthly Revenue($)</div>
              </div>
              <div className="colon_design">
                <div>:</div>
                <div>:</div>
              </div>
              <div className="resultvalues_align">
                <div>
                  <b>{resultValues?.sellerid}</b>
                </div>
                <div>
                  <b>{resultValues?.monthlyRevenue}</b>
                </div>
              </div>
              <div className="heading_align">
                <div>Static Premium($)</div>
                <div>Total Variable Premium($)</div>
              </div>
              <div className="colon_design">
                <div>:</div>
                <div>:</div>
              </div>
              <div className="resultvalues_align">
                <b>{resultValues?.staticPremium}</b>
                <br />
                <b>
                  {resultValues?.totalVariablePremium === null
                    ? resultValues?.totalVariablePremium
                    : totalVariablePremium}
                </b>
              </div>
              <div className="heading_align">
                <div>Quote Amount($)</div>
              </div>
              <div className="colon_design">
                <div>:</div>
              </div>
              <div className="resultvalues_align">
                <b>
                  {resultValues?.totalPremium === null
                    ? resultValues?.totalPremium
                    : Number(resultValues?.totalPremium).toFixed(2)}
                </b>
              </div>
              <div className="back_button">
                <button type="button" className="btn btn-dark" onClick={goBack}>
                  Back
                </button>
              </div>
            </div>
            <div className="product_data_table">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" style={{ textAlign: 'left' }}>
                      Product Name
                    </th>
                    <th scope="col" style={{ textAlign: 'left' }}>
                      Category
                    </th>
                    <th scope="col" style={{ textAlign: 'center' }}>
                      Rating
                    </th>
                    <th scope="col" style={{ textAlign: 'center' }}>
                      Revenue($)
                    </th>
                    <th scope="col" style={{ textAlign: 'center' }}>
                      Revenue Multiplier
                    </th>
                    <th scope="col" style={{ textAlign: 'center' }}>
                      Variable Premium($)
                    </th>
                  </tr>
                </thead>
                {productData && productData.loaded && (
                  <tbody>
                    <tr>
                      <td
                        scope="row"
                        colSpan={6}
                        style={{ textAlign: 'center' }}
                      >
                        <BeatLoader />
                      </td>
                    </tr>
                  </tbody>
                )}
                {productData && productData.length === 0 && (
                  <tbody>
                    <tr>
                      <td
                        scope="row"
                        colSpan={6}
                        style={{ textAlign: 'center' }}
                      >
                        No Records Found
                      </td>
                    </tr>
                  </tbody>
                )}
                {productData &&
                  productData.map((value, j) => {
                    return (
                      <tbody key={value?.title}>
                        <tr>
                          <td>{value?.title}</td>
                          <td>{value?.category}</td>
                          <td style={{ textAlign: 'center' }}>
                            {value?.rating}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {value?.monthlyRevenueEstimate}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {value?.variablePremBand + '%'}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {Number(value?.variablePrem).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    )
                  })}
              </table>
            </div>
          </div>
            )}
      </div>
    </>
  )
}

export default ResultPage
