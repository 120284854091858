import { useState } from 'react'

export default function useLocalStorageState (key, initialState) {
  const [state, setState] = useState(localStorage.getItem(key) || initialState)

  function setLocalStorageState (newState) {
    localStorage.setItem(key, newState)
    setState(newState)
  }

  return [state, setLocalStorageState]
}
