import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ResultPage from './Components/ResultPage'
import QuotePage from './Components/QuotePage'
import ProductNullPage from './Components/ProductNullPage'

function MainRoutes () {
  return (
    <div>
      <Routes>
        <Route path="" element={<QuotePage />} />
        <Route path="/" element={<QuotePage />} />
        <Route path="/result" element={<ResultPage />} />
        <Route path="/productResult" element={<ProductNullPage />} />
      </Routes>
    </div>
  )
}

export default MainRoutes
